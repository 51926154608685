// audioEngine.js
/* eslint-disable */

import { Metronome } from '@/components/score/Scorescripts/class';
import { useMetronomeStore } from './metronome';
import { useRoomStore } from './room';
import { defineStore } from 'pinia';
import * as Tone from 'tone';

let synths = {};

export const useAudioEngineStore = defineStore('audioEngine', {
    state: () => ({
        isLatencyMode: false,
        isInitialized: false,
        masterVolume: 50,
        instrumentVolumes: {
            beat: 50,
            prebeat: 50,
            guitar: 50,
            piano: 50,
            melody: 50,
            bass: 50,
            drums: 50,
        },
        currentMeasure: 1,
        currentBeat: 1,
        isPlaying: false,
        schedulingOffset: 0,
        scheduledEvents: [],
        currentLatency: 0,
        measures: [],
        metronomeSettings: {
            sound: 'beep',
            prebeatSound: 'voice',
            pitch: 880,
            isPlayingEighth: false,
        },
        playbackState: {
            beat: 1,
            measureIndex: 0,
            isPrebeat: false,
            bpm: 120,
            timeSignature: '4/4',
        }
    }),

    actions: {

        updateLatency(newLatency) {
            // Convert milliseconds to seconds for Tone.js scheduling
            this.schedulingOffset = newLatency / 1000;
        },

        updateLatencyRealtime(newLatency) {
            const oldOffset = this.schedulingOffset;
            this.schedulingOffset = newLatency / 1000;
            this.currentLatency = newLatency;

            if (!this.isPlaying) return;

            const currentTime = Tone.now();
            const adjustmentAmount = this.schedulingOffset - oldOffset;

            // Transport의 모든 스케줄된 이벤트 가져오기
            const allEvents = Tone.Transport._scheduledEvents;
            const currentTransportTime = Tone.Transport.seconds;

            // 아직 실행되지 않은 이벤트만 필터링
            Object.entries(allEvents).forEach(([id, event]) => {
                if (event.time > currentTransportTime) {
                    // 기존 이벤트 제거
                    Tone.Transport.clear(id);

                    // 새로운 시간으로 재스케줄링
                    const newTime = event.time + adjustmentAmount;
                    Tone.Transport.schedule(event.callback, newTime);
                }
            });

            // Transport의 loop 타이밍도 조정
            if (Tone.Transport.loop) {
                Tone.Transport.loopStart = Tone.Transport.loopStart + adjustmentAmount;
                Tone.Transport.loopEnd = Tone.Transport.loopEnd + adjustmentAmount;
            }
        },

        initialize(initialMeasures) {
            this.measures = initialMeasures;
            this.initializeSynths();
        },

        initializeSynths() {
            if (Object.keys(synths).length > 0) {
                return;
            }

            synths = {
                beep: new Tone.MembraneSynth({
                    pitchDecay: 0.008,
                    octaves: 2,
                    oscillator: { type: 'sine' },
                    volume: 4,
                    envelope: {
                        attack: 0.0006,
                        decay: 0.5,
                        sustain: 0,
                        release: 0.1
                    }
                }).toDestination(),

                click: new Tone.NoiseSynth({
                    noise: {
                        type: 'white',
                        playbackRate: 3,
                        volume: -8
                    },
                    envelope: {
                        attack: 0.001,
                        decay: 0.05,
                        sustain: 0,
                        release: 0.05,
                    }
                }).toDestination(),

                piano: new Tone.Sampler({
                    urls: {
                        "e4": "e4.mp3",
                    },
                    baseUrl: "/assets/samples/grand_piano/",
                    volume: "+9",
                }).toDestination(),

                guitar: new Tone.Sampler({
                    urls: {
                        "g4": "g2.mp3",
                    },
                    baseUrl: "/assets/samples/steel_guitar/",
                    volume: "0",
                }).toDestination(),

                melody: (() => {
                    // 메인 신디사이저
                    const melody = new Tone.Synth({
                        oscillator: {
                            type: "fmsine",          // FM 신디사이저로 변경
                            modulationType: "sine",
                            harmonicity: 3.01,        // 포먼트와 비슷한 하모닉스 생성
                            modulationIndex: 1.2
                        },
                        envelope: {
                            attack: 0.05,            // 목소리의 자연스러운 시작
                            decay: 0.2,
                            sustain: 0.5,            // 더 긴 서스테인
                            release: 0.4
                        }
                    });

                    // 포먼트 필터들
                    const formantFilter1 = new Tone.Filter({
                        type: "bandpass",
                        frequency: 600,              // 첫 번째 포먼트 주파수
                        Q: 2
                    });

                    const formantFilter2 = new Tone.Filter({
                        type: "bandpass",
                        frequency: 1500,             // 두 번째 포먼트 주파수
                        Q: 1.5
                    });

                    const formantFilter3 = new Tone.Filter({
                        type: "bandpass",
                        frequency: 2500,             // 세 번째 포먼트 주파수
                        Q: 2
                    });

                    // 보컬 특성을 위한 EQ
                    const eq = new Tone.EQ3({
                        low: -2,                     // 저음역 약간 줄임
                        mid: 4,                      // 중음역 강화
                        high: 2,                     // 고음역 약간 강화
                        lowFrequency: 400,
                        highFrequency: 2600
                    });


                    // 목소리의 미세한 움직임을 시뮬레이션하는 비브라토
                    const vibrato = new Tone.Vibrato({
                        frequency: 5.5,              // 자연스러운 비브라토 속도
                        depth: 0.1,                  // 약한 비브라토
                        type: "sine"
                    });

                    // 공간감을 위한 리버브
                    const reverb = new Tone.Reverb({
                        decay: 1.2,
                        preDelay: 0.01,
                        wet: 0.15
                    });

                    // 컴프레서
                    const compressor = new Tone.Compressor({
                        threshold: -20,
                        ratio: 3,
                        attack: 0.02,
                        release: 0.3,
                        knee: 5
                    });

                    // AutoFilter로 미세한 움직임 추가
                    const autoFilter = new Tone.AutoFilter({
                        frequency: 0.1,
                        type: "sine",
                        depth: 0.05,                 // 매우 미세한 변화
                        baseFrequency: 600,
                        octaves: 0.5,
                        wet: 0.1
                    }).start();

                    // 소프트 클리핑으로 부드러운 왜곡 추가
                    const softClipper = new Tone.Chebyshev({
                        order: 2,                    // 낮은 차수로 부드러운 왜곡
                    });
                    softClipper.wet.value = 0.1;

                    // 이펙트 체이닝
                    melody.chain(
                        formantFilter1,
                        formantFilter2,
                        formantFilter3,
                        eq,
                        vibrato,
                        autoFilter,
                        softClipper,
                        compressor,
                        reverb,
                        Tone.Destination
                    );

                    // 볼륨 조정
                    melody.volume.value = +24;

                    return melody;
                })(),

                bass: new Tone.Sampler({
                    urls: {
                        "A2": "a2.mp3",
                    },
                    baseUrl: "/assets/samples/clean_bass/",
                    volume: "+5",
                }).toDestination(),

                drums: new Tone.Sampler({
                    urls: {
                        "C1": "kick.wav",      // 36
                        "D1": "snare.mp3",     // 38
                        "F#1": "hihat.mp3",    // 42
                        "F1": "lowtom.mp3",  // 41
                        "Bb1": "openhihat.wav", //46
                        "B1": "midtom.mp3", // 47
                        "D2": "hightom.mp3",   // 50
                        "C#2": "crash.mp3",    // 49
                        "Eb2": "ride.mp3",     // 51
                    },
                    baseUrl: "/assets/samples/drums/",
                    volume: "-10",
                    onload: () => {
                        console.log("DrumKit samples loaded");
                    }
                }).toDestination(),

                voice: new Tone.Sampler({
                    //A(숫자)는 여성 보이스 카운팅
                    urls: {
                        "A1": "woman_1.mp3",
                        "A2": "woman_2.mp3",
                        "A3": "woman_3.mp3",
                        "A4": "woman_4.mp3",
                        "A5": "woman_5.mp3",
                        "A6": "woman_6.mp3",
                        "A7": "woman_7.mp3",
                        "A8": "woman_8.mp3",
                        "A9": "woman_9.mp3",
                    },
                    baseUrl: "/assets/samples/voice/",
                    volume: "+24",
                }).toDestination(),
            };
            this.isInitialized = true;
        },

        setupMeasures() {
            // measure 1의 설정으로 measure 0 두 개 생성
            const firstMeasureSettings = this.measures[0];
            const measureZeros = [
                {
                    ...firstMeasureSettings,
                    measure: -2,
                    measureIndex: -2,
                    bpm: firstMeasureSettings.bpm,
                    timeSignature: firstMeasureSettings.timeSignature,
                },
                {
                    ...firstMeasureSettings,
                    measure: -1,
                    measureIndex: -1,
                    bpm: firstMeasureSettings.bpm,
                    timeSignature: firstMeasureSettings.timeSignature,
                }
            ];

            // measure 0 두 개를 앞에 추가
            this.measures = [...measureZeros, ...this.measures];
        },

        setupMetronome() {
            this.measures = this.measures.map(measure => {
                const [numerator, denominator] = measure.timeSignature.split('/').map(Number);
                const bpm = Number(measure.bpm);

                let beatDuration = 60 / bpm;
                if (denominator === 8) {
                    beatDuration /= 2;
                }

                const measureDuration = beatDuration * numerator;
                const soundDuration = 0.05;

                const timelineEvents = [];
                for (let beat = 0; beat < numerator; beat++) {
                    const beatTime = beat * beatDuration;
                    // 4분음표 비트
                    timelineEvents.push({
                        type: 'metronome',
                        time: beatTime,
                        beat: beat + 1,
                        duration: soundDuration,
                        velocity: beat === 0 ? 1.0 : 0.8,
                        data: {
                            isFirstBeat: beat === 0
                        }
                    });

                    // denominator가 4일 때만 8분음표 추가
                    if (denominator === 4) {
                        timelineEvents.push({
                            type: 'metronome',
                            time: beatTime + (beatDuration / 2), // 비트 중간에 8분음표 추가
                            beat: null,
                            duration: soundDuration,
                            velocity: 0.5, // 8분음표는 더 약하게
                            data: {
                                isFirstBeat: false
                            }
                        });
                    }
                }

                return {
                    ...measure,
                    duration: measureDuration,
                    timelineEvents
                };
            });
        },

        playSound(time, event, isPrebeat) {
            // 레이턴시를 time에만 적용
            const adjustedTime = time + this.schedulingOffset;

            let duration = event.duration || 0.25;
            let velocity = event.velocity || 0.8;

            if (!isPrebeat && event.type === 'metronome' &&
                useRoomStore().currentRoomData?.isLatencyModalOpen &&
                !event.data.isFirstBeat) {
                return;
            }

            // 마스터 볼륨과 악기별 볼륨 적용
            const masterScale = this.masterVolume / 100;

            // 이벤트 타입에 따른 악기 볼륨 스케일 적용
            let instrumentScale = 1;
            switch (event.type) {
                case 'metronome':
                    instrumentScale = (isPrebeat ? this.instrumentVolumes.prebeat : this.instrumentVolumes.beat) / 100;
                    break;
                case 'bass':
                    instrumentScale = this.instrumentVolumes.bass / 100;
                    break;
                case 'drums':
                    instrumentScale = this.instrumentVolumes.drums / 100;
                    break;
                case 'piano':
                    instrumentScale = this.instrumentVolumes.piano / 100;
                    break;
                case 'guitar':
                    instrumentScale = this.instrumentVolumes.guitar / 100;
                    break;
                default:
                    instrumentScale = this.instrumentVolumes.melody / 100;
                    break;
            }

            // 최종 velocity 계산
            velocity *= masterScale * instrumentScale;
            if (isPrebeat) velocity *= 0.3;

            function noteToFrequency(note) {
                const A4 = 440;
                const NOTES = {
                    'C': -9, 'C#': -8, 'Db': -8,
                    'D': -7, 'D#': -6, 'Eb': -6,
                    'E': -5,
                    'F': -4, 'F#': -3, 'Gb': -3,
                    'G': -2, 'G#': -1, 'Ab': -1,
                    'A': 0, 'A#': 1, 'Bb': 1,
                    'B': 2
                };

                const matches = note.match(/^([A-G][#b]?)(\d+)$/);
                if (!matches) {
                    throw new Error('Invalid note format');
                }

                const [, noteName, octave] = matches;
                const semitonesFromA4 = NOTES[noteName] + (octave - 4) * 12;
                return A4 * Math.pow(2, semitonesFromA4 / 12);
            }

            switch (event.type) {
                case 'metronome':
                    // 프리비트가 아닐 때는 sound가 voice면서 beat가 없으면 리턴
                    if (!isPrebeat && this.metronomeSettings.sound === 'voice' && !event.beat) {
                        return;
                    }
                    // 프리비트일 때는 prebeatSound가 voice면서 beat가 없으면 리턴
                    if (isPrebeat && this.metronomeSettings.prebeatSound === 'voice' && !event.beat) {
                        return;
                    }

                    // voice가 아닐 때는 8분음표 설정 확인
                    if (!this.metronomeSettings.isPlayingEighth && event.beat === null) {
                        const currentSound = isPrebeat ? this.metronomeSettings.prebeatSound : this.metronomeSettings.sound;
                        if (currentSound !== 'voice') {
                            return;
                        }
                    }

                    if (isPrebeat) {
                        if (this.metronomeSettings.prebeatSound === 'voice') {
                            const beatNote = `A${event.beat}`;
                            synths.voice.triggerAttack(beatNote, adjustedTime, velocity);
                        } else if (this.metronomeSettings.prebeatSound === 'beep') {
                            if (event.data.isFirstBeat) {
                                synths.beep.triggerAttackRelease(this.metronomeSettings.pitch, duration, adjustedTime, velocity);
                            } else {
                                synths.beep.triggerAttackRelease(this.metronomeSettings.pitch / 2, duration, adjustedTime, velocity);
                            }
                        } else if (this.metronomeSettings.prebeatSound === 'click') {
                            synths.click.triggerAttackRelease(undefined, adjustedTime, velocity);
                        }
                    } else {
                        if (this.metronomeSettings.sound === 'voice') {
                            const beatNote = `A${event.beat}`;
                            synths.voice.triggerAttack(beatNote, adjustedTime, velocity);
                        } else if (this.metronomeSettings.sound === 'beep') {
                            if (event.data.isFirstBeat) {
                                synths.beep.triggerAttackRelease(this.metronomeSettings.pitch, duration, adjustedTime, velocity);
                            } else {
                                synths.beep.triggerAttackRelease(this.metronomeSettings.pitch / 2, duration, adjustedTime, velocity);
                            }
                        } else if (this.metronomeSettings.sound === 'click') {
                            synths.click.triggerAttackRelease(undefined, adjustedTime, velocity);
                        }
                    }
                    break;

                case 'bass':
                    const bassNote = event.data.note || 'C2';
                    const bassFreq = noteToFrequency(bassNote);
                    synths.bass.triggerAttackRelease(bassFreq, duration, adjustedTime, velocity);
                    break;

                case 'piano':
                case 'keyboard':
                    const pianoNote = event.data.note || 'C4';
                    const pianoFreq = noteToFrequency(pianoNote);
                    synths.piano.triggerAttackRelease(pianoFreq, duration, adjustedTime, velocity);
                    break;

                case 'guitar':
                    const guitarNote = event.data.note || 'C4';
                    const guitarFreq = noteToFrequency(guitarNote);
                    synths.guitar.triggerAttackRelease(guitarFreq, duration, adjustedTime, velocity);
                    break;

                case 'melody':
                    const melodyNote = event.data.note || 'C4';
                    const melodyFreq = noteToFrequency(melodyNote);
                    synths.melody.triggerAttackRelease(melodyFreq, duration, adjustedTime, velocity);
                    break;

                case 'drums':
                    const drumNote = event.data.note;
                    synths.drums.triggerAttackRelease(drumNote, duration, adjustedTime, velocity);
                    break;

                default:
                    console.warn(`Unknown event type: ${event.type}`);
                // const note = event.data.note || 'C4';
                // const freq = noteToFrequency(note);
                // synths.melody.triggerAttackRelease(freq, duration, time, velocity);
            }
        },

        async start(startOffset = 0, playbackSpeed = 1, prebeat = 0, startMeasureIndex = 0, endMeasure = null, isRepeat = false, isMetronomeOnly = false) {
            await this.cleanupTransport();

            if (!this.isInitialized || !this.measures || this.measures.length === 0) {
                console.warn('Audio engine not initialized or no measures');
                return false;
            }

            if (Tone.context.state !== 'running') {
                try {
                    await Tone.start();
                    console.log("Tone context started:", Tone.context.state);
                } catch (error) {
                    console.error('Failed to start Tone.js context:', error);
                    return false;
                }
            }

            let actualStartIndex = startMeasureIndex - prebeat;
            const adjustedStartOffset = (startOffset / 1000) + this.schedulingOffset;

            let prebeatMeasures = prebeat > 0 ?
                [...this.measures]
                    .filter(measure => measure.measureIndex >= actualStartIndex && measure.measureIndex < startMeasureIndex)
                    .map(measure => ({
                        ...measure,
                        bpm: measure.bpm * playbackSpeed,
                        duration: measure.duration / playbackSpeed,
                        timelineEvents: measure.timelineEvents.map(event => ({
                            ...event,
                            time: event.time / playbackSpeed,
                            duration: (event.duration || 0.25) / playbackSpeed
                        }))
                    }))
                : [];

            let mainMeasures = [...this.measures]
                .filter(measure => measure.measureIndex >= startMeasureIndex &&
                    (endMeasure === null || measure.measureIndex <= endMeasure))
                .map(measure => ({
                    ...measure,
                    bpm: measure.bpm * playbackSpeed,
                    duration: measure.duration / playbackSpeed,
                    timelineEvents: measure.timelineEvents.map(event => ({
                        ...event,
                        time: event.time / playbackSpeed,
                        duration: (event.duration || 0.25) / playbackSpeed
                    }))
                }));

            if (isMetronomeOnly) {
                prebeatMeasures = prebeatMeasures.map(measure => ({
                    ...measure,
                    timelineEvents: measure.timelineEvents.filter(event => event.type === 'metronome')
                }));

                mainMeasures = mainMeasures.map(measure => ({
                    ...measure,
                    timelineEvents: measure.timelineEvents.filter(event => event.type === 'metronome')
                }));
            }

            this.scheduledEvents = [];
            let accumulatedTime = adjustedStartOffset;
            const currentTime = Tone.Transport.seconds;

            prebeatMeasures.forEach(measure => {
                measure.startTime = accumulatedTime;
                measure.endTime = accumulatedTime + measure.duration;

                measure.timelineEvents.forEach(event => {
                    const absoluteTime = measure.startTime + event.time;

                    if (absoluteTime < currentTime || absoluteTime > measure.endTime) return;

                    const eventId = Tone.Transport.schedule((time) => {
                        if (event.type === 'metronome' && event?.beat) {
                            this.playbackState = {
                                beat: event.beat !== undefined ? event.beat : this.playbackState?.beat || 0,
                                measureIndex: startMeasureIndex,
                                isPrebeat: true,
                                bpm: measure.bpm,
                                timeSignature: measure.timeSignature
                            };
                        }
                        this.playSound(time, event, true);
                    }, absoluteTime);

                    this.scheduledEvents.push({
                        id: eventId,
                        time: absoluteTime,
                        measureIndex: measure.measureIndex,
                        event: event
                    });
                });

                accumulatedTime = measure.endTime;
            });

            let firstBeatTime = null;
            let lastBeatTime = null;

            mainMeasures.forEach((measure, index) => {
                measure.startTime = accumulatedTime;
                measure.endTime = accumulatedTime + measure.duration;

                measure.timelineEvents.forEach(event => {
                    const absoluteTime = measure.startTime + event.time;

                    if (index === 0 && event.type === 'metronome' && event.beat === 1) {
                        firstBeatTime = absoluteTime;
                    }
                    if (index === mainMeasures.length - 1 && event.type === 'metronome') {
                        lastBeatTime = absoluteTime;
                    }

                    if (absoluteTime < currentTime || absoluteTime > measure.endTime) return;

                    const eventId = Tone.Transport.schedule((time) => {
                        if (event.type === 'metronome' && event.beat !== null) {
                            this.playbackState = {
                                beat: event.beat !== undefined ? event.beat : this.playbackState?.beat || 0,
                                measureIndex: measure.measureIndex,
                                isPrebeat: false,
                                bpm: measure.bpm,
                                timeSignature: measure.timeSignature,
                            };
                        }
                        this.playSound(time, event, false);
                    }, absoluteTime);

                    this.scheduledEvents.push({
                        id: eventId,
                        time: absoluteTime,
                        measureIndex: measure.measureIndex,
                        event: event
                    });
                });

                accumulatedTime = measure.endTime;
            });

            if (isRepeat && mainMeasures.length > 0 && firstBeatTime !== null) {
                const loopOffset = 0.01;
                const lastMeasure = mainMeasures[mainMeasures.length - 1];

                Tone.Transport.loop = true;
                Tone.Transport.loopStart = Math.max(0, firstBeatTime - loopOffset);
                Tone.Transport.loopEnd = lastMeasure.endTime - loopOffset;
            } else {
                Tone.Transport.loop = false;
                Tone.Transport.schedule((time) => {
                    this.stop();
                }, accumulatedTime);
            }

            this.measures = [...prebeatMeasures, ...mainMeasures];
            this.isPlaying = true;
            Tone.Transport.seconds = currentTime;
            Tone.Transport.start();

            return true;
        },

        stop() {
            Tone.Transport.stop();
            Tone.Transport.cancel(0);
            Tone.Transport.clear();
            Tone.Transport.loop = false;

            Object.values(synths).forEach(synth => {
                if (synth.envelope) {
                    synth.envelope.cancel(0);
                }
            });

            this.scheduledEvents.forEach(event => {
                Tone.Transport.clear(event.id);
            });
            this.scheduledEvents = [];

            this.currentMeasure = 1;
            this.currentBeat = 1;
            this.isPlaying = false;
        },

        async fullCleanup() {
            // Transport 정리
            this.cleanupTransport();

            // 모든 신스와 이펙트 체인 정리
            Object.values(synths).forEach(synth => {
                if (synth.dispose) {
                    try {
                        // 연결된 모든 이펙트 노드들을 먼저 분리
                        synth.disconnect();

                        // 체인에 있는 모든 이펙트들도 정리
                        if (synth._effects) {
                            synth._effects.forEach(effect => {
                                if (effect.dispose) {
                                    effect.disconnect();
                                    effect.dispose();
                                }
                            });
                        }

                        // 마지막으로 신스 자체를 정리
                        synth.dispose();
                    } catch (error) {
                        console.warn('Error disposing synth:', error);
                    }
                }
            });

            // 신스 객체 초기화
            synths = {};

            // Tone.js 컨텍스트 상태 확인 및 정리
            if (Tone.context) {
                try {
                    // 활성 타임아웃 정리
                    Tone.context._timeouts.cancel(0);
                    // 모든 예약된 이벤트 정리
                    Tone.context.clearTimeout();
                } catch (error) {
                    console.warn('Error cleaning up Tone.js context:', error);
                }
            }

            // 약간의 지연을 주어 정리가 완료되도록 함
            await new Promise(resolve => setTimeout(resolve, 100));
        },

        async cleanupTransport() {
            this.isPlaying = false;
            this.scheduledEvents.forEach(event => {
                Tone.Transport.clear(event.id);
            });
            this.scheduledEvents = [];

            Tone.Transport.stop();
            Tone.Transport.cancel(0);
            Tone.Transport.clear();

            Tone.Transport.position = 0;
            Tone.Transport.seconds = 0;

            Tone.Transport.off('start');
            Tone.Transport.off('stop');
            Tone.Transport.off('pause');
            Tone.Transport.off('loop');

            Tone.Transport.loop = false;
            Tone.Transport.loopStart = 0;
            Tone.Transport.loopEnd = 0;

            if (Tone.context) {
                Tone.context.clearTimeout();
            }

            await new Promise(resolve => setTimeout(resolve, 50));
        },

        setVolume(param) {
            if (typeof param === 'number') {
                // 마스터 볼륨 조절 (0~100%)
                this.masterVolume = Math.max(0, Math.min(100, param));
            } else if (typeof param === 'object') {
                // 개별 악기 볼륨 조절 (0~100%)
                Object.entries(param).forEach(([instrument, volume]) => {
                    if (this.instrumentVolumes.hasOwnProperty(instrument)) {
                        this.instrumentVolumes[instrument] = Math.max(0, Math.min(100, volume));
                    }
                });
            }
        },

        setMetronomeSound(sound) {
            this.metronomeSettings.sound = sound;
        },

        // 예비박 소리 설정
        setPrebeatSound(sound) {
            this.metronomeSettings.prebeatSound = sound;
        },

        // 0-100 범위의 값을 440-1760Hz 범위로 변환
        setMetronomePitch(percentage) {
            // 로그 스케일로 변환하여 더 자연스러운 피치 변화를 구현
            const minHz = 440;  // A4
            const maxHz = 1760; // A6

            // 로그 스케일 변환
            const minLog = Math.log(minHz);
            const maxLog = Math.log(maxHz);
            const scale = (maxLog - minLog) / 100;

            // 0-100 값을 Hz로 변환
            const hz = Math.exp(minLog + (scale * percentage));

            this.metronomeSettings.pitch = Math.round(hz);
        },

        getMetronomeSound() {
            return this.metronomeSettings.sound;
        },

        getPrebeatSound() {
            return this.metronomeSettings.prebeatSound;
        },

        // Hz 값을 0-100 범위로 변환 (UI 표시용)
        getPitchPercentage() {
            const minHz = 440;
            const maxHz = 1760;
            const minLog = Math.log(minHz);
            const maxLog = Math.log(maxHz);

            const percentage = ((Math.log(this.metronomeSettings.pitch) - minLog) / (maxLog - minLog)) * 100;
            return Math.round(percentage);
        },

        // 현재 볼륨값 가져오기 (퍼센트 단위)
        getVolume(instrument = null) {
            if (instrument === null) {
                return this.masterVolume;
            }
            return this.instrumentVolumes[instrument] || null;
        },

        dispose() {
            // 1. Transport 정리
            this.fullCleanup();

            // 2. 신스만 정리
            Object.values(synths).forEach(synth => {
                synth.disconnect();
                synth.context._timeouts.cancel(0);
                synth.dispose();
            });

            // 3. 상태 초기화
            synths = {};
            this.isInitialized = false;
            this.currentMeasure = 1;
            this.currentBeat = 1;
            this.isPlaying = false;
        }
    }
});
