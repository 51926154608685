import { defineStore } from 'pinia';
import { ref, onValue, update, push, set, remove, get, query, orderByChild, equalTo } from 'firebase/database';
import { rtdb } from '../firebase';
import { useAuthStore } from './auth';
import { useMetronomeStore } from '@/store/metronome';
import { trackCreateRoom, trackJoinRoom, trackLeaveRoom, trackRemoveRoom, trackBPMChange, trackPlay, trackStop, trackGroupSession, trackAlbum, trackMeasureChange, trackAlbumFetch } from '@/analytics';

const STORAGE_KEYS = {
    ROOM_SETTINGS: 'roomSettings',
    LAST_ROOM: 'lastRoom',
    ROOM_HISTORY: 'roomHistory',
    ROOM_PREFERENCES: 'roomPreferences',
    METRONOME_SETTINGS: 'metronomeSettings',
    TEMPO_SETTINGS: 'tempoSettings',
    REPEAT_SETTINGS: 'repeatSettings',
    USER_PREFERENCES: 'userPreferences'
};

const initialState = {
    rooms: {},
    currentRoom: null,
    currentBeat: 1,
    currentAlbumId: null,
    currentRoomData: null,
    roomListener: null,
    settingScores: null,
    settingTimeout: null
};

export const useRoomStore = defineStore('room', {
    state: () => ({
        ...initialState
    }),

    getters: {
        isInRoom: (state) => !!state.currentRoom,
        computedRoomData: (state) => state.currentRoom ? state.rooms[state.currentRoom] || null : null,
        isPlayDisabled: (state) => !!state.settingScores
    },

    actions: {
        clearLocalStorage() {
            // 메트로놈과 룸 세팅 관련 데이터만 삭제
            const keysToRemove = [
                STORAGE_KEYS.METRONOME_SETTINGS,
                STORAGE_KEYS.TEMPO_SETTINGS,
                STORAGE_KEYS.REPEAT_SETTINGS
            ];

            keysToRemove.forEach(key => localStorage.removeItem(key));

            // 메트로놈, latency 관련 데이터 찾아서 삭제
            for (let i = 0; i < localStorage.length; i++) {
                const key = localStorage.key(i);
                if (key?.includes('metronome') ||
                    key?.includes('tempo') ||
                    key?.includes('score') ||
                    key?.includes('latency_')) {  // latency_ 로 시작하는 키 삭제 추가
                    localStorage.removeItem(key);
                }
            }
        },

        resetState() {
            // Pinia 스토어 상태를 부분적으로 리셋
            const partialReset = {
                currentRoom: null,
                currentBeat: 1,
                currentAlbumId: null,
                currentRoomData: null,
                roomListener: null,
                settingScores: null,
                settingTimeout: null
            };

            Object.assign(this, partialReset);

            // Metronome 스토어만 초기화
            const metronomeStore = useMetronomeStore();
            metronomeStore.isPlaying = false;

            if (metronomeStore.stop) {
                metronomeStore.stop();
            }
            if (metronomeStore.pause) {
                metronomeStore.pause();
            }
            if (metronomeStore.worker) {
                metronomeStore.worker.terminate();
                metronomeStore.worker = null;
            }
            if (metronomeStore.audioContext) {
                metronomeStore.audioContext.close();
                metronomeStore.audioContext = null;
            }
        },
        async startScoreSettings(settingType) {
            const authStore = useAuthStore();
            if (!this.currentRoom || !authStore.isAuthenticated) return false;

            const roomRef = ref(rtdb, `rooms/${this.currentRoom}`);
            const snapshot = await get(roomRef);
            const roomData = snapshot.val();

            if (roomData.settingScores) {
                const settingTime = roomData.settingScores.startTime;
                const now = Date.now();

                if (now - settingTime > 30000) {
                    await this.clearScoreSettings();
                } else if (roomData.settingScores.uid !== authStore.user.uid) {
                    return false;
                }
            }

            const settingData = {
                uid: authStore.user.uid,
                nickname: authStore.userProfile?.nickname || '익명',
                startTime: Date.now(),
                type: settingType
            };

            if (this.settingTimeout) {
                clearTimeout(this.settingTimeout);
            }

            await update(roomRef, { settingScores: settingData });
            this.settingScores = settingData;

            this.settingTimeout = setTimeout(async () => {
                await this.checkAndClearSettings(settingData);
            }, 30000);

            return true;
        },

        async checkAndClearSettings(originalSettings) {
            if (!this.currentRoom) return;

            const roomRef = ref(rtdb, `rooms/${this.currentRoom}`);
            const snapshot = await get(roomRef);
            const roomData = snapshot.val();

            if (roomData.settingScores?.startTime === originalSettings.startTime) {
                await this.clearScoreSettings();
            }
        },

        async clearScoreSettings() {
            if (!this.currentRoom) return;

            if (this.settingTimeout) {
                clearTimeout(this.settingTimeout);
                this.settingTimeout = null;
            }

            const roomRef = ref(rtdb, `rooms/${this.currentRoom}`);
            await update(roomRef, { settingScores: null });
            this.settingScores = null;
        },

        async finalizeScoreSettings(settings) {
            if (!this.currentRoom) return;

            if (this.settingTimeout) {
                clearTimeout(this.settingTimeout);
                this.settingTimeout = null;
            }

            const roomRef = ref(rtdb, `rooms/${this.currentRoom}`);
            await update(roomRef, {
                ...settings,
                settingScores: null
            });
            this.settingScores = null;
        },

        async fetchRooms() {
            const authStore = useAuthStore();
            if (!authStore.isAuthenticated) {
                console.error('User not authenticated');
                return;
            }

            this.clearRoomsListener();

            console.log('Fetching rooms...');
            const roomsRef = ref(rtdb, 'rooms');
            this.roomsListener = onValue(roomsRef, (snapshot) => {
                const allRooms = snapshot.val();
                console.log('All rooms:', JSON.parse(JSON.stringify(allRooms)));
                if (allRooms) {
                    this.rooms = allRooms;
                    console.log('Fetched rooms:', JSON.parse(JSON.stringify(this.rooms)));
                } else {
                    this.rooms = {};
                    console.log('No rooms found');
                }
            }, (error) => {
                console.error('Error fetching rooms:', error);
            });
        },

        clearRoomsListener() {
            if (this.roomsListener) {
                console.log('Clearing rooms listener');
                this.roomsListener();
                this.roomsListener = null;
            }
        },

        clearAllRoomListeners() {
            this.clearRoomsListener();
            if (this.roomListener) {
                console.log('Clearing room listener');
                this.roomListener();
                this.roomListener = null;
            }
        },

        async fetchCurrentRoom(roomId) {
            const authStore = useAuthStore();
            if (!authStore.isAuthenticated) {
                console.error('User not authenticated');
                return;
            }

            console.log('Fetching current room:', roomId);
            if (!roomId) {
                console.error('No roomId provided to fetchCurrentRoom');
                return;
            }
            const roomRef = ref(rtdb, `rooms/${roomId}`);
            try {
                const snapshot = await get(roomRef);
                if (snapshot.exists()) {
                    const roomData = snapshot.val();
                    if (roomData.isPersonal && roomData.createdBy !== authStore.user.uid) {
                        console.error('No access to this personal room');
                        return;
                    }
                    this.rooms = { ...this.rooms, [roomId]: roomData };
                    this.currentRoom = roomId;
                    this.currentRoomData = roomData;
                } else {
                    console.log('Room not found:', roomId);
                    this.currentRoom = null;
                    this.currentRoomData = null;
                }
            } catch (error) {
                console.error('Error fetching current room:', error);
            }
        },

        async checkRoomExists(roomId) {
            if (!roomId) return false;
            const roomRef = ref(rtdb, `rooms/${roomId}`);
            try {
                const snapshot = await get(roomRef);
                return snapshot.exists();
            } catch (error) {
                console.error("Error checking room existence:", error);
                return false;
            }
        },

        async joinRoom(roomId) {
            const authStore = useAuthStore();
            if (!authStore.isAuthenticated) {
                console.error('User not authenticated');
                return;
            }

            if (this.roomListener) {
                this.roomListener();
                this.roomListener = null;
            }

            await authStore.fetchUserProfile();
            const nickname = authStore.userProfile?.nickname || '익명';

            const roomRef = ref(rtdb, `rooms/${roomId}`);

            const roomSnapshot = await get(roomRef);
            const roomData = roomSnapshot.val();
            const currentParticipants = roomData?.participants || {};
            const participantCount = Object.keys(currentParticipants).length;

            const userInfo = {
                nickname: nickname,
                joinedAt: new Date().toISOString(),
                lastPing: new Date().toISOString(),
            };

            await update(roomRef, {
                [`participants/${authStore.user.uid}`]: userInfo,
            });

            this.currentRoom = roomId;

            await authStore.updateCurrentRoom(roomId);
            await this.fetchCurrentRoom(roomId);
            this.listenToRoomChanges();
            trackJoinRoom(roomId, {
                participantCount: participantCount + 1,
                user_id: authStore.user.uid
            });
        },

        async updateLastPing() {
            const authStore = useAuthStore();
            if (!authStore.isAuthenticated || !this.currentRoom) {
                console.error('User not authenticated or not in a room');
                return;
            }

            const pingRef = ref(rtdb, `rooms/${this.currentRoom}/participants/${authStore.user.uid}/lastPing`);
            await set(pingRef, new Date().toISOString());
        },

        async checkRoomNameDuplicate(name) {
            const roomsRef = ref(rtdb, 'rooms');
            const roomQuery = query(roomsRef, orderByChild('name'), equalTo(name));
            const snapshot = await get(roomQuery);
            return snapshot.exists();
        },

        async createRoom(roomData) {
            const authStore = useAuthStore();
            if (!authStore.isAuthenticated) {
                console.error('User not authenticated');
                return;
            }

            await authStore.fetchUserProfile();
            const nickname = authStore.userProfile?.nickname || '익명';
            const roomsRef = ref(rtdb, 'rooms');
            const newRoomRef = push(roomsRef);

            const defaultRoomData = {
                bpm: 120,
                createdAt: new Date().toISOString(),
                createdBy: authStore.user.uid,
                hasPassword: false,
                isPlaying: false,
                isPublic: true,
                isPersonal: false,
                name: roomData.name || '새 방',
                participants: {},
                prebeat: 1,
                soundType: 'sine',
                startTime: Date.now(),
                timeSignature: '4/4',
                totalBeats: 0,
                volume: 0.5,
            };

            const finalRoomData = { ...defaultRoomData, ...roomData };

            if (finalRoomData.isPersonal) {
                finalRoomData.isPublic = false;
                finalRoomData.hasPassword = true;
                finalRoomData.password = authStore.user.uid;
            } else {
                finalRoomData.isPublic = !finalRoomData.password;
                finalRoomData.hasPassword = !!finalRoomData.password;
            }

            finalRoomData.participants[authStore.user.uid] = {
                nickname: nickname,
                joinedAt: new Date().toISOString(),
                lastPing: new Date().toISOString(),
                isHost: true
            };

            trackCreateRoom(newRoomRef.key, {
                isPersonal: finalRoomData.isPersonal,
                name: finalRoomData.name,
                user_Id: authStore.user.uid
            });

            await set(newRoomRef, finalRoomData);
            this.currentRoom = newRoomRef.key;
            this.currentRoomData = finalRoomData;
            await authStore.updateCurrentRoom(newRoomRef.key);
            this.listenToRoomChanges();
            return newRoomRef.key;
        },

        async deleteRoom() {
            const authStore = useAuthStore();
            if (!authStore.isAuthenticated || !this.currentRoom) {
                console.error('User not authenticated or not in a room');
                return;
            }

            const roomRef = ref(rtdb, `rooms/${this.currentRoom}`);
            await remove(roomRef);

            // 로컬 스토리지와 앱 상태 초기화
            this.clearLocalStorage();
            this.resetState();

            trackRemoveRoom(this.currentRoom);
            await authStore.updateCurrentRoom(null);
        },

        async leaveRoom() {
            const authStore = useAuthStore();
            const metronomeStore = useMetronomeStore();

            trackStop(this.currentRoom);

            if (!authStore.isAuthenticated || !this.currentRoom) {
                console.error('User not authenticated or not in a room');
                return;
            }

            if (this.roomListener) {
                this.roomListener();
                this.roomListener = null;
            }

            metronomeStore.pause();

            // 참가자 데이터 삭제
            const roomParticipantsRef = ref(rtdb, `rooms/${this.currentRoom}/participants`);
            const participantsSnapshot = await get(roomParticipantsRef);
            if (participantsSnapshot.exists()) {
                const participants = participantsSnapshot.val();
                const updates = {};
                Object.keys(participants).forEach(participantId => {
                    if (participantId.startsWith(authStore.user.uid)) {
                        updates[participantId] = null;
                    }
                });
                await update(roomParticipantsRef, updates);
            }

            // 로컬 스토리지와 앱 상태 초기화
            this.clearLocalStorage();
            this.resetState();

            trackLeaveRoom(this.currentRoom);
            await authStore.updateCurrentRoom(null);
        },

        async updateRoomSettings(roomId, settings) {
            const authStore = useAuthStore();
            if (!authStore.isAuthenticated || !roomId) {
                console.error('User not authenticated or no roomId provided');
                return;
            }

            const roomRef = ref(rtdb, `rooms/${roomId}`);
            const previousSettings = this.rooms[roomId] || {};

            try {
                await update(roomRef, settings);

                if (this.rooms[roomId]) {
                    this.rooms[roomId] = { ...this.rooms[roomId], ...settings };
                }

                const updatedSnapshot = await get(roomRef);
                const updatedRoomData = updatedSnapshot.val();

                if (settings.bpm !== undefined && settings.bpm !== previousSettings.bpm) {
                    trackBPMChange(roomId, {
                        previousBpm: previousSettings.bpm,
                        newBpm: settings.bpm,
                        isPlaying: updatedRoomData.isPlaying
                    });
                }

                if (this.currentRoom === roomId) {
                    this.currentRoomData = updatedRoomData;
                }

            } catch (error) {
                console.error('Error updating room settings:', error);
                throw error;
            }
        },

        async updateCurrentBeat(roomId, currentBeat) {
            if (!roomId) {
                console.error('No roomId provided');
                return;
            }

            console.log(`Updating current beat for room ${roomId}: ${currentBeat}`);
            const currentBeatRef = ref(rtdb, `rooms/${roomId}/currentBeat`);
            await set(currentBeatRef, currentBeat);
            this.currentBeat = currentBeat;
        },

        async updateCurrentAlbum(albumId) {
            const authStore = useAuthStore();
            if (!authStore.isAuthenticated || !this.currentRoom) {
                console.error('User not authenticated or not in a room');
                return;
            }

            console.log('Updating current album:', albumId);
            const albumRef = ref(rtdb, `rooms/${this.currentRoom}/currentAlbum`);
            const previousAlbumId = this.currentAlbumId;

            await set(albumRef, albumId);
            this.currentAlbumId = albumId;

            trackAlbum(this.currentRoom, {
                previousAlbumId,
                newAlbumId: albumId,
            });
            if (albumId){
            trackAlbumFetch(albumId,'');
            }

            console.log(`Updated current album in room ${this.currentRoom}: ${albumId}`);
        },

        listenToRoomChanges() {
            if (!this.currentRoom) {
                console.error('방에 참여하지 않았습니다.');
                return;
            }

            console.log(`방 변경 사항을 듣고 있습니다: ${this.currentRoom}`);
            const roomRef = ref(rtdb, `rooms/${this.currentRoom}`);
            const metronomeStore = useMetronomeStore();

            if (this.roomListener) {
                this.roomListener();
                this.roomListener = null;
            }

            let previousParticipantCount = 0;
            let wasPlaying = false;

            this.roomListener = onValue(roomRef, (snapshot) => {
                const roomData = snapshot.val();
                if (roomData) {
                    this.rooms = { ...this.rooms, [this.currentRoom]: roomData };
                    this.currentRoomData = { ...roomData };
                    this.currentAlbumId = roomData.currentAlbum;
                    this.settingScores = roomData.settingScores || null;

                    if (roomData.settingScores) {
                        if (roomData.isPlaying) {
                            this.updateRoomSettings(this.currentRoom, { isPlaying: false });
                        }
                    }

                    const isNowPlaying = roomData.isPlaying;
                    const isPlayingChanged = wasPlaying !== isNowPlaying;
                    const currentParticipantCount = Object.keys(roomData.participants || {}).length;

                    if (currentParticipantCount !== previousParticipantCount) {
                        trackGroupSession(this.currentRoom, {
                            previousCount: previousParticipantCount,
                            currentCount: currentParticipantCount,
                            isPlaying: isNowPlaying
                        });
                        previousParticipantCount = currentParticipantCount;
                    }

                    if (isPlayingChanged) {
                        if (isNowPlaying && !roomData.settingScores) {
                            metronomeStore.start(roomData.startTime);
                            trackPlay(this.currentRoom, {
                                bpm: roomData.bpm,
                                timeSignature: roomData.timeSignature,
                                soundType: roomData.soundType,
                                totalParticipants: currentParticipantCount,
                                isAlbumPlaying: !!this.currentAlbumId,
                                measure: metronomeStore.currentMeasure
                            });
                        } else {
                            console.log('Tracking stop event...');
                            trackStop(this.currentRoom, {
                                isAlbumPlaying: !!this.currentAlbumId,
                                measure: metronomeStore.currentMeasure
                            });
                            console.log('Stopping metronome...');
                            metronomeStore.stop();
                        }
                        wasPlaying = isNowPlaying;
                    }

                    if (roomData.repeatSettings) {
                        const { startMeasure, endMeasure, isEnabled } = roomData.repeatSettings;
                        metronomeStore.setLoop(isEnabled, startMeasure, endMeasure);
                    }

                    if (roomData.tempoSettings) {
                        const { multiplier } = roomData.tempoSettings;
                        metronomeStore.setPlaybackSpeed(multiplier);
                    }

                    if (roomData.bpm !== metronomeStore.bpm) {
                        metronomeStore.updateBPM(roomData.bpm);
                    }

                    if (roomData.currentBeat !== metronomeStore.currentBeat) {
                        metronomeStore.setCurrentBeat(roomData.currentBeat);
                    }

                    if (!isNowPlaying && roomData.lastStoppedMeasure !== metronomeStore.currentMeasure) {
                        metronomeStore.setCurrentMeasure(roomData.lastStoppedMeasure);
                        trackMeasureChange(this.currentRoom, {
                            previousMeasure: metronomeStore.currentMeasure,
                            currentMeasure: roomData.lastStoppedMeasure,
                            timeSignature: roomData.timeSignature,
                            totalBeats: roomData.totalBeats,
                            currentBeat: roomData.currentBeat
                        });
                    }
                } else {
                    console.log(`방 ${this.currentRoom}이 더 이상 존재하지 않습니다.`);
                    this.rooms = { ...this.rooms };
                    delete this.rooms[this.currentRoom];

                    const metronomeStore = useMetronomeStore();
                    metronomeStore.stop();

                    this.clearLocalStorage();
                    this.resetState();

                    trackLeaveRoom(this.currentRoom);
                }
            });
        },

        async checkRoomParticipation() {
            const authStore = useAuthStore();
            if (!authStore.isAuthenticated) {
                return false;
            }

            await authStore.fetchUserProfile();
            const currentRoomId = authStore.currentRoomId;

            if (!currentRoomId) {
                return false;
            }

            const roomRef = ref(rtdb, `rooms/${currentRoomId}`);
            try {
                const snapshot = await get(roomRef);
                if (snapshot.exists()) {
                    this.currentRoom = currentRoomId;
                    await this.fetchCurrentRoom(currentRoomId);
                    this.listenToRoomChanges();
                    return true;
                } else {
                    await authStore.updateCurrentRoom(null);
                    this.currentRoom = null;
                    this.currentRoomData = null;
                    return false;
                }
            } catch (error) {
                console.error("Error checking room participation:", error);
                return false;
            }
        },
    },
});