// metronome.js
/*eslint-disable*/

import { defineStore } from 'pinia';
import { ref, computed, watch } from 'vue';
import { useNetworkLatencyStore } from './networkLatencyStore';
import { useAudioEngineStore } from './audioEngine';
import { useXMLParserStore } from '@/store/XMLParser.js';
import { useMidiStore } from './midi';
import { useScoreInfoStore } from './InfoParser';
import { useRoomStore } from './room';
import * as Tone from 'tone';
import { trackPlay, trackMetronomePlayDuration, trackGroupMetronomeStart, trackGroupMetronomeStop } from '@/analytics';
import { useAlbumStore } from './albumStore';

export const useMetronomeStore = defineStore('metronome', () => {
    const isPlaying = ref(false);
    const currentBeat = ref(0);
    const prebeatCount = ref(0);
    const bpm = ref(120);
    const timeSignature = ref('4/4');
    const volume = ref(0.5);
    const soundType = ref('sine');
    const audioDeviceDelay = ref(Math.max(0, parseInt(localStorage.getItem('metronomeAudioDeviceDelay') || '1')));
    const measureSettings = ref([]);
    const playbackSpeed = ref(1.0);

    let startMeasure = 1;
    let endMeasure = null;
    let isLoopEnabled = false;

    const currentMeasure = ref(1);
    const measureCount = ref(1);
    const isPaused = ref(true);
    const prebeat = ref(Math.max(0, parseInt(localStorage.getItem('prebeat') || '0')));
    const lastStoppedMeasure = ref(1);
    const startingMeasure = ref(1);
    const isFirstBeat = ref(true);
    const isPrebeat = ref(false);

    const networkLatencyStore = useNetworkLatencyStore();
    const scoreInfoStore = useScoreInfoStore();
    const xmlParserStore = useXMLParserStore();
    const midiStore = useMidiStore();
    const albumStore = useAlbumStore();
    const roomStore = useRoomStore();
    const audioEngine = useAudioEngineStore();

    const totalMeasures = computed(() => {
        return typeof scoreInfoStore.getTotalMeasures === 'function'
            ? scoreInfoStore.getTotalMeasures()
            : scoreInfoStore.getTotalMeasures;
    });

    const beatsPerMeasure = computed(() => {
        const [numerator] = timeSignature.value.split('/').map(Number);
        return numerator || 4;
    });

    const currentMeasureBPM = computed(() => {
        if (!measureSettings.value.length) return bpm.value;

        const currentSetting = measureSettings.value.find(
            setting => setting.measure === currentMeasure.value
        );

        return currentSetting ? currentSetting.bpm : bpm.value;
    });

    const displayBPM = computed(() => {
        return Math.round(currentMeasureBPM.value * playbackSpeed.value);
    });

    const ensureLatencyMeasurement = async () => {
        // room이 있을 때만 레이턴시 체크
        if (!roomStore.isInRoom) {
            console.log('No room connected, skipping latency measurement');
            return;
        }

        if (networkLatencyStore.isRemeasuring) {
            console.log('Latency measurement already in progress. Waiting for it to complete...');
            while (networkLatencyStore.isRemeasuring) {
                await new Promise(resolve => setTimeout(resolve, 100));
            }
            return;
        }

        if (!networkLatencyStore.isInitialized) {
            console.log('Initializing network latency measurement...');
            await networkLatencyStore.setupLatencyMeasurement();
        }
        console.log('Network latency measurement completed.');
    };

    const initializeDelays = () => {
        if (!localStorage.getItem('metronomeAudioDeviceDelay')) {
            audioDeviceDelay.value = 1;
            localStorage.setItem('metronomeAudioDeviceDelay', '1');
        }
    };

    initializeDelays();
    networkLatencyStore.setupLatencyMeasurement();

    const userBPMOverride = ref(false);

    const updateBPM = (newBPM, source = 'user') => {
        bpm.value = newBPM;
        if (source === 'user') {
            userBPMOverride.value = true;
        }
        if (roomStore.isInRoom && roomStore.currentRoom) {
            roomStore.updateRoomSettings(roomStore.currentRoom, { bpm: newBPM });
        }
    };

    const updateTimeSignature = (newTimeSignature) => {
        timeSignature.value = newTimeSignature;
    };

    const generateMeasureSettings = (startMeasure = 1) => {
        if (!xmlParserStore.scorePartwise?.parts) {
            // XML이 없는 경우의 기본 설정 (room 유무와 무관)
            const totalMeasureCount = totalMeasures.value || 100;
            measureSettings.value = Array.from({ length: totalMeasureCount }, (_, index) => ({
                measure: index + 1,
                measureIndex: index,
                timeSignature: timeSignature.value,
                bpm: bpm.value,
                swing: null
            }));
            return measureSettings.value;
        }

        const measureList = xmlParserStore.scorePartwise.parts.find(part => part.id === 'P1')?.measures;
        if (!measureList) {
            measureSettings.value = [];
            return [];
        }

        const settings = measureList.map((measure, index) => {
            const measureNumber = parseInt(measure.number);

            let currentTimeSignature = 'N/A';
            if (measure.attributes?.time) {
                currentTimeSignature = `${measure.attributes.time.beats}/${measure.attributes.time.beatType}`;
            }

            let currentBPM = 'N/A';
            let currentSwing = 'N/A';

            for (const element of measure.elements) {
                const sound = 'sound' in element ? element.sound : element;
                if (!sound) continue;

                if (sound.tempo) currentBPM = sound.tempo;
                if (sound.swing?.first) {
                    currentSwing = {
                        first: parseInt(sound.swing.first) || null,
                        second: parseInt(sound.swing.second) || null,
                        type: sound.swing.swingType ?? 'eighth',
                    };
                }
            }

            return {
                measure: measureNumber,
                measureIndex: index,
                timeSignature: currentTimeSignature,
                bpm: currentBPM,
                swing: currentSwing
            };
        });

        // 첫 마디의 기본값 설정
        settings[0].timeSignature = settings[0].timeSignature === 'N/A' ? timeSignature.value : settings[0].timeSignature;
        settings[0].bpm = settings[0].bpm === 'N/A' ? bpm.value : settings[0].bpm;
        settings[0].swing = settings[0].swing === 'N/A' ? null : settings[0].swing;

        // 이전 마디의 유효한 설정을 이어받도록 수정
        let lastValidTimeSignature = settings[0].timeSignature;
        let lastValidBPM = settings[0].bpm;
        let lastValidSwing = settings[0].swing;

        for (let i = 1; i < settings.length; i++) {
            if (settings[i].timeSignature === 'N/A') {
                settings[i].timeSignature = lastValidTimeSignature;
            } else {
                lastValidTimeSignature = settings[i].timeSignature;
            }

            if (settings[i].bpm === 'N/A') {
                settings[i].bpm = lastValidBPM;
            } else {
                lastValidBPM = settings[i].bpm;
            }

            if (settings[i].swing === 'N/A') {
                settings[i].swing = lastValidSwing;
            } else {
                lastValidSwing = settings[i].swing;
            }
        }

        measureSettings.value = settings;
        return measureSettings.value;
    };

    const start = async (serverStartTime = null, lastStoppedBeat = 1) => {
        if (isPlaying.value) return;

        isPlaying.value = true;
        isPaused.value = false;

        if (Tone.context.state !== 'running') {
            try {
                await Tone.start();
                console.log('Audio context started successfully');
            } catch (error) {
                console.log('Failed to start audio context:', error);
                return;
            }
        }

        // 시간 동기화 (room 있을 때)
        let startDelay = 0;
        if (roomStore.isInRoom) {
            const currentTime = performance.timeOrigin + performance.now();
            const syncedCurrentTime = currentTime + networkLatencyStore.averageTimeOffset;
            startDelay = serverStartTime - syncedCurrentTime;
        }

        // 항상 generateMeasureSettings 실행
        const initialMeasureSettings = generateMeasureSettings(startingMeasure.value);

        audioEngine.initialize(initialMeasureSettings);
        audioEngine.setupMeasures();

        // 레이턴시 모달용 메트로놈 설정
        if (roomStore.currentRoomData?.isLatencyModalOpen) {
            audioEngine.setupMetronome({
                clickOnlyFirstBeat: true  // 첫 박자만 소리나게 하는 옵션 추가
            });
        } else {
            audioEngine.setupMetronome();
        }

        // MIDI/XML이 있는 경우에만 로드
        if (xmlParserStore.scorePartwise) {
            midiStore.loadMIDIFromXML();
        }

        let success;
        if (!isLoopEnabled) {
            success = await audioEngine.start(startDelay, playbackSpeed.value, prebeat.value, currentMeasure.value - 1);
        } else {
            success = await audioEngine.start(startDelay, playbackSpeed.value, prebeat.value, startMeasure - 1, endMeasure - 1, isLoopEnabled);
        }

        if (!success) {
            console.error('Failed to start audio engine');
            stop();
            return;
        }

        // 분석 트래킹 (room 있을 때만)
        if (roomStore.isInRoom) {
            const participantCount = Object.keys(roomStore.currentRoomData?.participants || {}).length;
            if (participantCount > 1) {
                trackGroupMetronomeStart(roomStore.currentRoom, participantCount);
            }
        }
        trackPlay();

        startingMeasure.value = currentMeasure.value;
    };


    const stop = () => {
        isPlaying.value = false;
        isPaused.value = true;
        prebeatCount.value = 0;
        lastStoppedMeasure.value = measureCount.value;
        currentMeasure.value = measureCount.value;
        currentBeat.value = 0;

        audioEngine.stop();
        Tone.Transport.stop();
        Tone.Transport.cancel();

        isFirstBeat.value = true;
    };

    const stopAndUpdateRoom = () => {
        stop();
        if (roomStore.isInRoom && roomStore.currentRoom) {
            roomStore.updateRoomSettings(roomStore.currentRoom, {
                isPlaying: false,
                lastStoppedMeasure: currentMeasure.value
            });
        }
    };

    const pause = () => {
        if (!isPlaying.value) return;
        lastStoppedMeasure.value = measureCount.value;
        isPlaying.value = false;
        isPaused.value = true;

        audioEngine.stop();

        if (window.scheduledTimers) {
            window.scheduledTimers.forEach(timerId => clearTimeout(timerId));
            window.scheduledTimers = [];
        }
    };

    const resetPlaybackSpeed = () => {
        playbackSpeed.value = 1.0;
        if (audioEngine && typeof audioEngine.updatePlaybackSpeed === 'function' && isPlaying.value) {
            audioEngine.updatePlaybackSpeed(1.0);
        }
    };

    const resetMetronome = () => {
        stop();
        currentBeat.value = 0;
        currentMeasure.value = 1;
        measureCount.value = 1;
        lastStoppedMeasure.value = 1;
    };

    const updateVolume = (newVolume) => {
        volume.value = newVolume;
    };

    const updateSoundType = (newSoundType) => {
        soundType.value = newSoundType;
    };

    const updateAudioDeviceDelay = (newDelay) => {
        const delay = Math.max(0, parseInt(newDelay));
        audioDeviceDelay.value = delay;
        localStorage.setItem('metronomeAudioDeviceDelay', delay.toString());
    };

    const updatePrebeat = (newPrebeat) => {
        prebeat.value = Math.max(0, parseInt(newPrebeat));
        if (roomStore.isInRoom && roomStore.currentRoom) {
            roomStore.updateRoomSettings(roomStore.currentRoom, {
                prebeat: prebeat.value,
            });
        }
    };

    const setCurrentBeat = (beat) => {
        if (!isPlaying.value) {
            currentBeat.value = beat;
        }
    };

    const toggleMetronome = async () => {
        if (isPlaying.value) {
            pause();
            // room 상태 업데이트 (room 있을 때만)
            if (roomStore.isInRoom && roomStore.currentRoom) {
                await roomStore.updateRoomSettings(roomStore.currentRoom, {
                    isPlaying: false,
                    lastStoppedMeasure: currentMeasure.value
                });
            }
        } else {
            let startTime;
            if (roomStore.isInRoom && roomStore.currentRoom) {
                startTime = networkLatencyStore.getCurrentServerTime() + 1000;
                await roomStore.updateRoomSettings(roomStore.currentRoom, {
                    isPlaying: true,
                    startTime: startTime,
                    bpm: bpm.value,
                    prebeat: prebeat.value,
                    timeSignature: timeSignature.value,
                    lastStoppedMeasure: lastStoppedMeasure.value - 1,
                });
            } else {
                startTime = performance.now() + 1000;
            }
            await start(startTime, 1);
        }
    };

    const setCurrentMeasure = (measure) => {
        if (!isPlaying.value) {
            currentMeasure.value = measure;
            measureCount.value = measure;
            lastStoppedMeasure.value = measure;
        }
    };

    const jumpToMeasure = (measure) => {
        if (isPlaying.value) {
            return;
        }

        if (measure < 1 || measure > totalMeasures.value) {
            return;
        }

        currentMeasure.value = measure;
        measureCount.value = measure;
        lastStoppedMeasure.value = measure;
        currentBeat.value = 0;

        if (roomStore.isInRoom && roomStore.currentRoom) {
            roomStore.updateRoomSettings(roomStore.currentRoom, {
                lastStoppedMeasure: measure,
                lastStoppedBeat: 1
            });
        }
    };

    const increaseCurrentMeasure = () => {
        if (isPlaying.value) {
            console.log("Cannot change measure while playing");
            return;
        }

        if (currentMeasure.value < totalMeasures.value) {
            currentMeasure.value++;
            lastStoppedMeasure.value = currentMeasure.value;
            if (roomStore.isInRoom && roomStore.currentRoom) {
                roomStore.updateRoomSettings(roomStore.currentRoom, {
                    lastStoppedMeasure: currentMeasure.value,
                    lastStoppedBeat: currentBeat.value
                });
            }
        }
    };

    const decreaseCurrentMeasure = () => {
        if (isPlaying.value) {
            return;
        }

        if (currentMeasure.value > 1) {
            currentMeasure.value--;
            lastStoppedMeasure.value = currentMeasure.value;
            if (roomStore.isInRoom && roomStore.currentRoom) {
                roomStore.updateRoomSettings(roomStore.currentRoom, {
                    lastStoppedMeasure: currentMeasure.value,
                    lastStoppedBeat: currentBeat.value
                });
            }
        }
    };

    const setLoop = (isEnabled, start = null, end = null) => {
        isLoopEnabled = isEnabled;

        if (!isEnabled) {
            startMeasure = 1;
            endMeasure = null;
            return;
        }

        if (start !== null) {
            startMeasure = Math.max(1, start);
        }

        if (end !== null) {
            endMeasure = Math.min(end, totalMeasures.value);
        }
    };

    const setPlaybackSpeed = (value) => {
        playbackSpeed.value = value;
        if (audioEngine && typeof audioEngine.updatePlaybackSpeed === 'function' && isPlaying.value) {
            audioEngine.updatePlaybackSpeed(value);
        }
    };

    // Watchers
    watch(() => audioEngine.playbackState, (newState) => {
        if (isPlaying.value && newState) {  // newState가 존재하는지 확인
            currentBeat.value = newState.beat;
            currentMeasure.value = newState.measureIndex + 1;
            measureCount.value = newState.measureIndex + 1;
            isPrebeat.value = newState.isPrebeat;
            if (newState.timeSignature) {  // timeSignature가 존재할 때만 업데이트
                timeSignature.value = newState.timeSignature;
            }
        }
    });

    watch(() => roomStore.currentRoomData?.lastStoppedMeasure, (newMeasure) => {
        if (roomStore.isInRoom && !isPlaying.value && newMeasure !== undefined && newMeasure !== currentMeasure.value) {
            setCurrentMeasure(newMeasure);
        }
    });

    watch(() => roomStore.currentRoomData?.lastStoppedBeat, (newBeat) => {
        if (roomStore.isInRoom && !isPlaying.value && newBeat !== undefined && newBeat !== currentBeat.value) {
            setCurrentBeat(newBeat);
        }
    });

    watch(() => roomStore.currentRoomData, (newRoomData) => {
        if (roomStore.isInRoom && newRoomData && !isPlaying.value) {
            setCurrentMeasure(newRoomData.lastStoppedMeasure || 1);
            setCurrentBeat(newRoomData.lastStoppedBeat || 1);
        }
    }, { immediate: true });

    watch(audioDeviceDelay, (newValue) => {
        audioDeviceDelay.value = newValue;
        updateAudioDeviceDelay(newValue);
    });

    // Watch playbackSpeed changes
    watch(playbackSpeed, (newSpeed) => {
        if (isPlaying.value) {
            audioEngine.updatePlaybackSpeed(newSpeed);
        }
    });

    return {
        isPlaying,
        currentBeat,
        prebeatCount,
        bpm,
        timeSignature,
        volume,
        soundType,
        playbackSpeed,
        audioDeviceDelay,
        beatsPerMeasure,
        currentMeasure,
        totalMeasures,
        isPaused,
        prebeat,
        lastStoppedMeasure,
        currentMeasureBPM,
        displayBPM,
        measureSettings,
        start,
        stop,
        stopAndUpdateRoom,
        pause,
        resetMetronome,
        updateBPM,
        updateTimeSignature,
        updateVolume,
        updateSoundType,
        updateAudioDeviceDelay,
        updatePrebeat,
        toggleMetronome,
        decreaseCurrentMeasure,
        increaseCurrentMeasure,
        jumpToMeasure,
        setCurrentMeasure,
        setCurrentBeat,
        setLoop,
        setPlaybackSpeed,
        isPrebeat,
        resetPlaybackSpeed
    };
});

export default useMetronomeStore;